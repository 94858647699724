<template>
    <!-- 赠品申请列表 -->
    <div style="height:calc(100% - 0.6rem);background:#fff;padding-top:0.6rem;">
        <div class="autoFucus" style="position:fixed;top:0;left:0;z-index: 888;">
            <nut-cell :show-icon="true" title="" :desc="searchform.is_payName" custom-class-name='typeClass' @click.native="openSwitchType('is_payIsVisible')"></nut-cell>
            <nut-picker :is-visible="is_payIsVisible" :list-data="is_payoptions" title="搜索类型" @close="closeSwitchType('is_payIsVisible')" @confirm="confirmTypePay"></nut-picker>
            <!-- <nut-cell :show-icon="true" title="" :desc="searchform.statusName" custom-class-name='typeClass' @click.native="openSwitchType('statusIsVisible')"></nut-cell>
            <nut-picker :is-visible="statusIsVisible" :list-data="statusoptions" title="搜索类型" @close="closeSwitchType('statusIsVisible')" @confirm="confirmType"></nut-picker> -->
            <nut-textinput  class="my-input" type="search" v-model="searchform.oid" :placeholder="placeholder" ref="myInput" @keyup.enter="submit()" >
                <template v-slot:clearBtn>
                    <nut-icon type="cross"></nut-icon>
                </template>
            </nut-textinput>
            <div class="searchBtn" @click="submit()">搜索</div>
        </div>
        <nut-scroller
            :is-un-more="isUnMore" 
            :is-loading="isLoading"
            :type="'vertical'"
            :scrollTo="scrollTo"
            @loadMore="loadMoreVert"
            @pulldown="pulldown"
            @scrollChange="scrollChange"
            :loadingTxt="'加载中...'"
            style="height: calc(100%);"
        > 
            <div slot="list" class="nut-vert-list-panel">
                <div class="nut-vert-list-item" v-for="(item, index) of orderList" @click="getOrderDetail(item)" :key="index">
                    <div class="flexDiv">
                        <p style="flex: 2;">{{item.work_to_order.oid}}</p>
                        <p>发起人:{{item.admin_name}}</p>
                    </div>
                    <div class="flexDiv" style="margin-top:10px;">
                        <p style="flex: 2;">
                            <span>
                                {{item.work_status_detail}}
                            </span>
                        </p>
                        <p>
                            <span>
                                {{item.refund_to_work.name}}:{{item.refund_to_work.admin_name}}
                            </span>
                        </p>
                    </div>
                    <div v-if="item.status==1" class="flexDiv" style="margin-top:10px;">
                        <p style="flex: 2;">
                            <span>
                                {{item.created_at}}
                            </span>
                        </p>
                        <nut-button @click.stop="getrecallExamineGift(item)" type="red" shape="circle" small>撤回</nut-button>
                    </div>
                </div>
            </div>
        </nut-scroller>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { examineGift,recallExamineGift,customerList,customerView } from '@/api/api.js'
export default {
    components: {
    },
    data(){
        return {
            statusIsVisible: false,
            statusoptions: [
                [{label:'1',value:'处理中',},
                {label:'2',value:'拒绝',},
                {label:'3',value:'已完成',},
                {label:'4',value:'撤回',}]
            ],
            is_payIsVisible: false,
            is_payoptions: [
                [{label:1,value:'已支付',},
                {label:2,value:'未支付',},
                {label:0,value:'全部',}]
            ],
            placeholder:'请输入订单号',
            orderList:[],
            searchform:{
                // status:'1',
                // statusName: '处理中',
                oid:'',
                is_pay:1,
                is_payName: '已支付',
                page: 1,
                pageNum:20,
            },
            maxPages: 4,
            isUnMore: false,
            isLoading: false,
            timer: null,
            scrollTo:-100,
            loadingport:false,
            work_id:'',
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getorderList();
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        openSwitchType(param) {
            this[`${param}`] = true;
        },
        closeSwitchType(param) {
            this[`${param}`] = false;
        },
        confirmType(chooseData) {
            this.scrollTo=0;
            this.searchform.statusName = `${chooseData[0].value}`;
            this.searchform.status = `${chooseData[0].label}`;
            this.searchform.oid='';
            this.submit();
        },
        confirmTypePay(chooseData) {
            console.log(chooseData);
            this.scrollTo=0;
            this.searchform.is_payName = `${chooseData[0].value}`;
            this.searchform.is_pay = chooseData[0].label;
            this.searchform.oid='';
            this.submit();
        },
        submit(){
            this.scrollTo=0;
            this.searchform.page=1;
            this.searchform.pageNum=10;
            this.orderList = [];
            this.getorderList();
        },
        scrollChange(value){
            this.scrollTo=value;
        },
        loadMoreVert() {
            this.isLoading = true;
            if (this.searchform.page > this.maxPages) {
                this.isUnMore = true;
                this.isLoading = false;
            } else {
                this.searchform.page = ++this.searchform.page;
                this.getorderList();
            }
        },
        pulldown() {
            this.orderList = [];
            this.isLoading = true;
            this.searchform.page = 1;
            this.getorderList();
        },
        getorderList(){
            let parmas = this.searchform;
            examineGift(parmas).then(res => {
                if(res.data.code==200){
                    this.isLoading = false;
                    this.isUnMore = false;
                    this.orderList=[...this.orderList, ...res.data.data.data];
                    this.maxPages=Math.ceil(res.data.data.total/20)
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },
        //撤回
        getrecallExamineGift(data){
            console.log(data)
            this.work_id=data.id;
            let that=this;
            this.$dialog({title: "确定要撤回该流程",closeBtn:true,  //显式右上角关闭按钮
                onOkBtn(event) {  //确定按钮点击事件
                    console.log(that.work_id)
                    this.close(); //关闭对话框
                    let parmas = {work_id:that.work_id};
                    recallExamineGift(parmas).then(res => {
                        if(res.data.code==200){
                            that.$toast.success('撤回成功',{duration: 2000});
                            that.orderList = [];
                            that.getorderList();
                        }else{
                            this.$toast.fail(res.data.msg,{duration: 2000});
                        }
                    });
                },
                // onCancelBtn(event) {  //取消按钮点击事件，默认行为关闭对话框
                //     alert("cancelBtn");
                //     //return false;  //阻止默认“关闭对话框”的行为
                // },
                // onCloseBtn(event) { //右上角关闭按钮点击事件
                //     alert("closeBtn");
                //     //return false;  //阻止默认“关闭对话框”的行为
                // },
                // closeCallback(target) {
                //     alert("will close");  //对话框关闭回调函数，无论通过何种方式关闭都会触发
                // }
            });
        },
        getOrderDetail(data){
            this.$router.push({
                path:'/giftorderDetails',
                query:{
                    id:data.id
                }
            })
        },
    }
}
</script>
<style scoped>
.nut-button.small{
    height:26px;
}
.autoFucus {
    background: #fff;
    padding: 0.1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width:calc(100% - 20px);
    /* margin:0 auto; */
}
.autoFucus .my-input {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}
.autoFucus .searchBtn {
    margin-left: 10px
}
.searchBtn{
    font-size: 14px;
}
.nut-vert-list-item{
    font-size: 14px;
    padding:0.1rem 0.2rem;
}

.nut-vert-list-item:nth-child(2n){
    background: #fafafa;
}
.flexDiv{
    display: flex;
    margin-bottom: 6px;
    align-items: center;
}
</style>
<style>
    .autoFucus .nut-textinput input{
        height: 0.4rem;
    }
</style>
